import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";

import DOMPurify from "dompurify";
import marked from "marked";
import { element } from "prop-types";

import { Checkbox, Modal, SimpleLoading } from "~/components";
import { Foot } from "~/components/Modal/styles";
import { useFetch, useApi } from "~/hooks";
import useIdleLogout from "~/hooks/useIdleLogout";
import { useAuth } from "~/services";
import { ChangeLogContainer } from "~/services/store/me/styles";
import { useLocation, useParams } from "react-router-dom";

/**
 * Module context.
 */
// eslint-disable-next-line import/prefer-default-export
export const meContext = createContext({});

export function MeProvider({ children }) {
  const api = useApi();
  const { search } = useLocation();
  const { t } = useTranslation("modal");
  const { isAuthenticated, logout } = useAuth();

  let lastLogin = {};

  const storedLastLogin = localStorage.getItem("dd-last-login");

  if (storedLastLogin) {
    try {
      lastLogin = JSON.parse(storedLastLogin);
    } catch (error) {
      console.error("Error parsing last login data:", error);
    }
  }

  const [group, onGroup] = useState(lastLogin);
  const [showChangeLogModal, setShowChangeLogModal] = useState(false);

  const query = new URLSearchParams(search).get("q");

  const isLastLoginInHierarchyOrGroup = (user) => {
    const { grupos, hierarquia } = user;

    const idMatch = hierarquia.some(
      ({ id, cliente }) => id === lastLogin.value && cliente === lastLogin.label
    );
    if (idMatch) return true;

    const idGrupoMatch = grupos.some(
      ({  grupo }) => grupo === lastLogin.label
    );
    if (idGrupoMatch) return true;

    return false;
  };

  const meFetcher = async (params) => {
    const response = await api().get(params);

    if (response.status === 200) {
      if (response.data.data) {
        const userAreSameInLastLogin = isLastLoginInHierarchyOrGroup(
          response.data.data
        );

        if (userAreSameInLastLogin) {
          onGroup(lastLogin);
        } else {
          onGroup({});
          localStorage.removeItem("dd-last-login");
        }
      } else onGroup({});
    }

    return response.data;
  };

  const customFetcher = async (params) => {
    const response = await api().get(params);
    return response.data;
  };

  const { data: splashScreen } = useFetch({
    url: isAuthenticated ? "/admin/splashScreen" : null,
    customFetcher,
  });
  const { data: user, isValidating } = useFetch({
    url: isAuthenticated ? "/user/me" : null,
    customFetcher: meFetcher,
  });

  useEffect(() => {
    if (
      query === "diligencia" ||
      query === "cadastral" ||
      query === "batches"
    ) {
      if (splashScreen?.data) {
        setShowChangeLogModal(true);
      }
    }
  }, [splashScreen, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      localStorage.setItem("dd-last-login", JSON.stringify(group));
    }
  }, [group]);

  const dontShowAgain = useCallback(async () => {
    await api().delete("/admin/splashScreen");
    setShowChangeLogModal(false);
  }, [setShowChangeLogModal]);

  const newGroup = (val) => {
    onGroup(val);
  };

  const values = {
    isAdmin: user?.data.usuario?.perfil?.id === 5,
    userTypes: { ictsAdmin: 5 },
    user: {
      ...user?.data,
      isVisualizador: () => user?.data.usuario?.perfil?.id === 1,
      isRevisor: () => user?.data.usuario?.perfil?.id === 2,
      isGestor: () => user?.data.usuario?.perfil?.id === 3,
      isMaster: () => user?.data.usuario?.perfil?.id === 4,
      isAdminICTS: () => user?.data.usuario?.perfil?.id === 5,
      isAPI: () => user?.data.usuario?.perfil?.id === 6,
      canOpenBatches: () => !!user?.data?.usuario?.can_open_lots,
    },
    group,
    newGroup,
  };

  const { showIdleAlert, onShowIdleAlert, formattedTime } = useIdleLogout();

  if (!user) {
    if (isValidating) {
      return <SimpleLoading size={"lg"} />;
    } else {
      logout();
      return null;
    }
  }

  return (
    <meContext.Provider value={values}>
      {children}
      {showIdleAlert && (
        <Modal
          show
          onShow={onShowIdleAlert}
          title={t("modal.idle", { formattedTime })}
          hasActions={false}
          titleOnly
          size="lg"
        />
      )}
      {splashScreen?.data && showChangeLogModal && (
        <Modal
          header={<></>}
          action={{
            label: t('actions.dismiss'),
            onClick: () => { setShowChangeLogModal(false); },
            // label: t('actions.confirm'),
            // onClick: () => {dontShowAgain()},
          }}
          cancelName={t("actions.confirm")}
          goBackOnCancel={() => { dontShowAgain() }}
          // cancelName={t("actions.dismiss")}
          // goBackOnCancel={() => { setShowChangeLogModal(false); }}
          show
          title= "‎"
          size="lg"
          hasActions={true}
          
        >
          <ChangeLogContainer
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(marked(splashScreen?.data)),
            }}
          />
        </Modal>
      )}
    </meContext.Provider>
  );
}

/**
 * Module hook.
 */
export const useMe = () => useContext(meContext);

MeProvider.propTypes = {
  children: element.isRequired,
};
